<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialogSortSection"
        persistent
        max-width="600px"
        scrollable
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sắp xếp thứ tự section</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSortSection = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="p-0">
                  <draggable :list="data_draggable">
                    <ul
                      class="list-unstyled drag-css"
                      v-for="(section, i) in data_draggable"
                      :key="i"
                    >
                      <b-media tag="li">
                        <h4 class="mt-0 mb-1 font-weight-bold">
                          {{ section.section_title }}
                        </h4>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              class="font-weight-bold"
              text
              outlined
              @click="btnSortSection"
              >Cập nhật</v-btn
            >
            <v-btn color="darken-1" text @click="dialogSortSection = false"
              >Đóng</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import draggable from "vuedraggable";
import Swal from "sweetalert2";
import ApiService from "@/service/api.service";
export default {
  name: "SortSectionModal",
  components: {
    draggable,
  },
  data() {
    return {
      data_draggable: [],
    };
  },
  computed: {
    ...mapState({
      indexSkill: (state) => state.contentTestBuilderStore.indexSkill,
    }),
    ...mapGetters({
      skills: "contentTestBuilderStore/skills",
    }),
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    dialogSortSection: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogSortSection;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setDialogSortSection",
          value
        );
      },
    },
  },
  watch: {
    dialogSortSection: function (val) {
      if (val == true) {
        this.setDataInput();
      }
    },
  },
  methods: {
    setDataInput() {
      let data_skill = this.skills[this.indexSkill];
      this.data_draggable = [...data_skill.sections];
    },
    btnSortSection() {
      let vm = this;

      Swal.fire({
        title: "Are you sure you want to change?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, do it!",
      }).then((result) => {
        if (result.value) {
          let data_sort = vm.data_draggable.map((section, index) => {
            return {
              section_id: section.id,
              offset: index + 1,
            };
          });
          let data = {
            data_sort: data_sort,
          };
          vm.is_call_api = true;
          ApiService.post("prep-app/test/draft/section/sort", data)
            .then(function (res) {
              if (res.status == 200) {
                for (let iSec = 0; iSec < vm.skills[vm.indexSkill].sections.length; iSec++) {
                  for (let iSort = 0; iSort < data_sort.length; iSort++) {
                    if (vm.skills[vm.indexSkill].sections[iSec].id === data_sort[iSort].section_id) {
                      vm.skills[vm.indexSkill].sections[iSec].offset = data_sort[iSort].offset;
                    }
                  }
                }
                vm.skills[vm.indexSkill].sections.sort(function (x, y) {
                  return x.offset - y.offset;
                });
                vm.skills[vm.indexSkill].sections = vm.refreshSection(
                  vm.skills[vm.indexSkill].sections
                );
                vm.$set(vm.skills);
                vm.is_call_api = false;
                vm.$toasted.success("Sort section successfully !!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.dialogSortSection = false;
              }
            })
            .catch(function (error) {
              if (error) {
                vm.is_call_api = false;
                vm.$toasted.error(
                  "Something Wrong. Please try again or contact admin !!",
                  {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration: 4000,
                  }
                );
              }
            });
        }
      });
    },
    refreshSection(sections) {
      for (let iS = 0; iS < sections.length; iS++) {
        sections[iS].offset_question = this.recursiveOffsetQuesOfSec(
          sections,
          iS
        );
      }
      return sections;
    },
    recursiveOffsetQuesOfSec(sections, index) {
      let offset = 0;
      if (index == 0) {
        return 0;
      }
      offset = sections[index - 1].total_question;
      return offset + this.recursiveOffsetQuesOfSec(sections, index - 1);
    },
  },
};
</script>

<style lang="css" scoped>
.drag-css {
  cursor: pointer;
}
</style>
